* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  background-color: black;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0;
}

input::-webkit-input-placeholder {
  color: #c8c8c8;
}

/*Firefox 19+ versions*/
input::-moz-placeholder {
  color: #c8c8c8;
}

/*IE  versions*/
input::-ms-placeholder {
  color: #c8c8c8;
}

/*Latest modern browsers */
input::placeholder {
  color: #c8c8c8;
}
